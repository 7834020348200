import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./style.css"

export default class Contactus extends Component {
    render = () => {
        return (
            <div className="body-bg-style-3 inner-page contactpage">
                <div className="page-wrapper">
                    

                    <section className="bg-bread">
                      <div className="bg-banner">
                        <div className="title-breadcaumb-left">
                            <h1>Contact</h1>
                            <h6>Contacter-nous maintenant !</h6>
                             <p> "N'hésitez pas à nous contacter pour toute demande d'information, de service ou de collaboration. Notre équipe dévouée est là pour répondre à vos questions et vous aider de la meilleure façon possible. Remplissez simplement le formulaire ou envoyez-nous un e-mail à contact@itdevsolution.dz. Nous vous répondrons dans les plus brefs délais. Merci de votre intérêt pour notre entreprise."</p>  		
                            
                            <Link to="" className="button-header">Contactez-Nous</Link>
                        </div>
                        <div className="overlay-img--cubeLg1 position-absolute b-6"></div>
                        <div className="imagebreadcrumb-right ">
                        <img src="../partenaire.png" alt="Image 1"/>
                        </div>
                      </div>
                    </section>

<div className="inner-page-banner">
<div className="container text-center">
        <div className="containerpartener">
                   <div className="columncontacthome">
                        <img src="../icon-contact/adresse.png" alt="icon adresse"/>
                         
                         <h1>Adresse</h1>
                         <p>Tour n° 1 Hamma, 170 Rue Hassiba Benbouali, Alger</p>
                    </div>
                     <div className="columncontacthome">
                       <img src="../icon-contact/courrier.png" alt="icon email"/>
                         <h1>Email </h1>
                         <p>contact@itdevsolution.dz</p>
                     </div>

                    

                    <div className="columncontacthome">
                    <img src="../icon-contact/telephoner.png" alt="icon email"/>
                    <h1>Téléphone </h1>
                          <p>023 82 43 10 Fax: 023 82 41 79 </p>
                    </div>
        </div>
        
	</div>
    </div>
                    
<div className="container-fluid p-0 overflow-hidden bg-brand-primay dark-bg align-items-center">
    <div className="row">
        <div className="col-12 col-md-6">
            <img className="img-fit-cover b-6" src="../image-06.jpg"alt=""srcSet=""/>
        </div>
        <div className="col-12 col-md-6 dark-bg py-5 px-5" id="">
             <section className="body-contact">
                    <div className="wrapper">
                       <header>ENVOYEZ NOUS UN MESSAGE</header>
                          <form action="#">

                            <div className="dbl-field">
                                 <div className="field">
                                       <input type="text" name="name" placeholder="Nom*" />
                                       <i className='fas fa-user icon-contact-form'></i>
                                 </div>
                                <div className="field">
                                    <input type="text" name="email" placeholder="Email*" />
                                    <i className='fas fa-envelope icon-contact-form'></i>
                                </div>
                            </div>

                            <div className="dbl-field">
                                 <div className="field">
                                     <input type="text" name="phone" placeholder="Numéro de téléphone" />
                                     <i className='fas fa-phone-alt icon-contact-form'></i>
                                </div>
                                <div className="field">
                                  <input type="text" name="website" placeholder="Site Web" />
                                  <i className='fas fa-globe icon-contact-form'></i>
                                </div>
                           </div>

                           <div className="message">
                                 <textarea placeholder="Message" name="message"></textarea>

                                 <i className="fas fa-message icon-contact-form-message">message</i>
                            </div>

                          <div className="button-area">
                             <button type="submit">Envoyer</button>
                             <span></span>
                          </div>
                     </form>
                  </div>
            </section>
         </div>
    </div>
</div>


      <script src="script.js"></script>



                    
                        
         
               
    


                </div>
            </div>
        )
    }
}
