import React, { Component } from "react";
import { Link } from "react-router-dom";


export default class CaseStudies extends Component {
    render = () => {
        return (
           
        <div className="body-bg-style-3 inner-page">
            <div className="page-wrapper">
                    <svg className="bg-shape inner-page-shape-banner-right reveal-from-right move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_01" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_01)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>

                    <svg className="bg-shape inner-page-shape-banner-left reveal-from-left move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_02)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>
               
                    <section className="bg-bread">
                    <div className="bg-banner">
                        <div className="title-breadcaumb-left">
                            <h1>Espace Carrière</h1>
                            
                             <h6>Façonnez Votre Avenir avec Nous : Découvrez de Nouvelles Opportunités et Rejoignez notre Équipe !</h6>            
                            <p className="text-justify"> Notre portail de carrières offre un environnement stimulant pour les esprits ambitieux à la recherche de défis passionnants et de possibilités d'épanouissement professionnel. Nous invitons les talents prometteurs à rejoindre notre équipe et à contribuer à notre vision d'excellence et d'innovation."</p>
                            <Link to="" className="button-header">Contactez-Nous</Link>
                        </div>
                        <div className="imagebreadcrumb-right">
                        <div className="overlay-img--cubeLg1 position-absolute b-6"></div>

                        <img src="../../solutions/career-header.png" alt="Image 1"/>
                        </div>
                    </div>
                    </section>

                    <div className="inner-page-carriere">
                      <div className="inner-page-banner">
                        <div className="container text-center">
                            <h1>Nous recrutons</h1>
                            <p className="section-subheading text-center">
                            IT Dev Solution est une Entreprise de Services Numériques qui accompagne les entreprises et organismes nationaux dans leurs projets de digitalisation. Nous sommes une entreprise en pleine croissance et nous recherchons des talents pour rejoindre notre équipe.
                               Si vous êtes passionné par les technologies de l'information et que vous souhaitez contribuer à la transformation digitale de l'Algérie, nous vous invitons à nous rejoindre.
                               Nous recrutons des profils dans les domaines suivants :
                        </p>
                                               
                        </div>                   
                     </div>
                    </div>
                <div className="containercarriere ">

                    <div className="columncarriere ">
                          <div className="imagecarriere ">
                            <img src="../carriere/carriere-dev-web-fni.jpeg" alt="Image 1"/>
                                 <div className="overlaycarriere ">
                                   <h2>Développement web et mobile</h2>
                                 </div>
                          </div>
                    </div>

                    <div className="columncarriere ">
                       <div className="imagecarriere ">
                         <img src="../carriere/Infrastructure-informatique-carriere-fni.png" alt="Image 1"/>
                          <div className="overlaycarriere ">
                           <h2>Infrastructure informatique</h2>                   
                          </div>
                        </div>           
                    </div>

                    <div className="columncarriere ">
                       <div className="imagecarriere ">
                          <img src="../carriere/business-intelligence-big-data-carriere-fni.jpg" alt="Image 1"/>
                          <div className="overlaycarriere ">
                             <h2>Big Data et BI</h2>                   
                         </div>
                       </div>
                    </div>
        
                    <div className="columncarriere">
                       <div className="imagecarriere ">
                         <img src="../carriere/cybersecurite-carriere-fni.jpg" alt="Image 1"/>
                          <div className="overlaycarriere ">
                            <h2>Cybersécurité</h2>                    
                          </div>
                       </div>         
                    </div>

                    <div className="columncarriere ">
                      <div className="imagecarriere ">
                          <img src="../../../carriere/technico-commercial-carriere-fni.jpeg" alt="Image 1"/>
                            <div className="overlaycarriere ">
                                <h2>Technico-commercial</h2>
                            </div>
                      </div>  
                    </div>
                </div>

               


                
                        
                    

                 

        </div>
    </div>
            
        )
        
    }
}
