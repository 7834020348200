import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import AOS from "aos";
import Contact1 from "../contact_for/Contact1";


import { FeaturesSection } from "../Featuresection/FeaturesSection";
export default class Home extends Component {
    componentDidMount() {
        AOS.init({
            duration: 500,
            offset: 200,
            easing: "ease-in-out",
            once: true,
            delay: 100
        });
    };

    render = () => {
       
        
        const settings = {
            dots: true,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            delay: 2555 ^ 2
        };
        const settings1 = {
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,

            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
        };
       

        return (
            <>
            
                <div className="banner wrapper small-agency-home-header-bg " id="top">
                    

                <div className="container">
                     <div className="banner-content">
              
                            <h6>Bienvenue à</h6>
                        
                            <h1>IT DEV Solution Spa</h1>
                            <p className="section-subheading1 para-text text-justify ">En tant qu’entreprise publique de services numériques, nous sommes déterminés à jouer un rôle essentiel dans la révolution numérique de notre économie. Chez ITDS, nous mettons en œuvre nos ressources internes et collaborons étroitement avec nos partenaires pour concrétiser ce projet stratégique.</p>
                            
                            <h3>Our Business is your success</h3>
                        
                            <Link to="/service/services" className="custom-btn btn-big grad-style-ef page-scroll">Découvrez Nos Services</Link>
                        
                     </div>
                     
                    </div>
                  
                </div>
              
                <div >
                    </div>
                    <div className="body-bg-style-1 ">
                <div className="page-wrapper">
                    <svg className="bg-shape inner-page-shape-banner-right reveal-from-right move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_01" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_01)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>

                    <svg className="bg-shape inner-page-shape-banner-left reveal-from-left move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_02)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>

                    <div className="inner-page-banner">
                        <div className="container text-center">
                            <h1>Nos solutions</h1>
                            <p>« IT Dev Solution Spa » propose des solutions sur mesure adaptées à votre secteur d'activité et à la phase de croissance de votre entreprise.</p>

                      
                        </div>
                    
                    </div>
                    

                

                    
                </div>
            </div>
                <section className="services z-3">

                    <div className="container">

             <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-6 m-b-30" data-aos="fade-up">
                                <Link to="/Conseil/et/Intégration" target="_blank" rel="noreferrer" className="service-box text-center content-block">
                                    <div className="box-bg grad-style-cd"></div>
                                    <span className="icon-container grad-style-ab-light">
                                        <i className="ml-des-62-Precision-compasses-graphics-design-tool-interface txt-grad-cd"><img src="./4.svg" alt="dsqfr"/></i>
                                    </span>
                                    <h5 className="text-center">Conseil </h5>
                                    <p className="color-p-desc">Audit et diagnostic des sytèmes d'information, Définition de stratégies IT alignées sur les objectifs métiers, Assistance & maitrise d'ouvrage</p>
                                </Link>
                            </div>

                            <div className="col-lg-6 col-md-6 m-b-30" data-aos="fade-up">
                                <Link to="/service/Développement/sur/mésure" target="_blank" rel="noreferrer" className="service-box text-center content-block">
                                    <div className="box-bg grad-style-ab"></div>
                                    <span className="icon-container grad-style-ab-light">
                                        <i className="ml-des-57-upload-download-sync-application-device-mobile-computer txt-grad-ab"><img src="./2.svg" alt="dsqfr"/></i>
                                    </span>
                                    <h5 className="text-center">Développement sur-mesure </h5>
                                    <p className="color-p-desc">Conception d'applications métiers selon les besoins spécifiques, Développement web\mobile, Technologies innovantes : IA, Big Data, Business Intelligence </p>
                                </Link>
                            </div>

                            

                            <div className="col-lg-6 col-md-6 m-b-30" data-aos="fade-up">
                                <Link to="/service/Intégration" target="_blank" rel="noreferrer" className="service-box text-center content-block">
                                    <div className="box-bg grad-style-ab"></div>
                                    <span className="icon-container grad-style-ab-light">
                                        <i className="ml-symtwo-34-cursor-click-browser-website-arrow-computer txt-grad-ab"><img src="./1.svg" alt="dsqfr"/></i>
                                    </span>
                                    <h5 className="text-center">Intégration de solutions</h5>
                                    <p className="color-p-desc">Implémentation de logiciels du marché, Paramétrage et customisation, Interfaçage avec systèmes existants</p>
                                </Link>
                            </div>

                            <div className="col-lg-6 col-md-6 m-b-30" data-aos="fade-up">
                                <Link to="/service/Sécurité" target="_blank" rel="noreferrer" className="service-box text-center content-block">
                                    <div className="box-bg grad-style-ef"></div>
                                    <span className="icon-container grad-style-ab-light">
                                        <i className="ml-dat-63-database-computer-network-internet-globe-server txt-grad-ef"><img src="./3.svg" alt="dsqfr"/></i>
                                    </span>
                                    <h5 className="text-center">Sécurité</h5>
                                    <p className="color-p-desc">Audit de sécurité et tests d'intrusion, Stratégie de Cybersécurité, Logiciels de cybersécurité, Mise en place de SOC mutualisés
 </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="about-us">
                    <svg className="bg-shape shape-about reveal-from-right" xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>

                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_02)" d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z"
                        />
                    </svg>
                    <video className="video-play-bg " autoPlay loop muted >
  <source className="" src="./IT.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>
              
 
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 ml-auto apropos">
                                <h1 className="black-header">Qui sommes-nous ?</h1>
                                <p className="text-justify">IT Dev Solution est une entreprise de services numériques, filiale à 100% du Fonds National d'Investissement (FNI). Notre mission est d'accompagner les entreprises et organismes algériens dans leur transformation digitale grâce à des solutions innovantes, sécurisées et conformes.  </p>
                                <p className="text-justify">Notre expertise s'étend sur l'ensemble de la chaîne de valeur des systèmes d'information : conseil, intégration de solutions, développement spécifique, BPM, hébergement sécurisé dans le cloud en Algérie, infogérance, cybersécurité, conformité réglementaire...</p>
                                <div className="mt-4 ">
                                         <Link to="/A propos" type="button" className="custom-btn btn-big light-bg grad-style-ab"> Lire Plus</Link>
                                </div>
                                
                               
                            </div>
                        </div>
                    </div>
                </section>
             
                <FeaturesSection title="Stratégie et perspectives" slice={3} item={false} />

                 

                <section className="case-study small-agency-case-study">
                    <svg className="bg-shape shape-case reveal-from-left" xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_04" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>

                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_04)" d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z"
                        />
                    </svg>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 order-lg-2">
                                <Slider className="case-study-slider" {...settings}>
                                    <div className="item">
                                        <h2>La passion</h2>
                                        <p className="text-justify">Nous croyons en l'importance d'une atmosphère de travail harmonieuse et positive, où chacun se sent soutenu et valorisé. Nous sommes une équipe unie, animée par la passion pour la technologie et le développement, mais également par l'amour et le respect que nous avons les uns pour les autres.

Dans notre famille IT DEV Solution, nous cherchons constamment à améliorer cette dynamique. Nous nous engageons à cultiver un environnement où l'entraide, la communication ouverte et la collaboration sont encouragées. Nous reconnaissons que chacun apporte ses propres forces et compétences, et nous nous efforçons de les mettre en valeur. </p>
                                       
                                    </div>

                                    <div className="item">
                                        <h2>La qualité</h2>
                                        <p className="text-justify">Chez IT Dev Solution, la qualité est une priorité essentielle. En mettant l'accent sur des normes rigoureuses et des processus de contrôle de qualité robustes, nous nous engageons à fournir des solutions fiables et durables à nos clients. Nous nous efforçons continuellement à améliorer nos services en intégrant les meilleures pratiques et en mettant en œuvre des tests approfondis pour garantir des résultats de premier ordre. Notre engagement envers l'excellence se reflète dans chaque aspect de notre travail, assurant ainsi la satisfaction totale de nos clients et renforçant notre réputation d'excellence dans l'industrie des services numériques.</p>
                                       
                                    </div>

                                    <div className="item">
                                        <h2>Penser client</h2>
                                        <p className="text-justify"> Penser comme un client, se mettre à votre place pour l'autocritique et la prévention des problèmes avant même le test de votre produit. Cette approche nous permet non seulement de comprendre vos besoins et vos attentes, mais aussi d'anticiper vos préoccupations et de vous offrir des solutions adaptées. En nous mettant constamment à votre place, nous restons connectés à vos réalités et à votre business, ce qui nous permet de vous proposer des produits et des services qui répondent précisément à vos besoins. Notre objectif est de créer une expérience client exceptionnelle, qui vous donne envie de revenir vers nous et de recommander nos produits à d'autres. </p>
                                       
                                    </div>
                                </Slider>
                                <div className="btn-container">
                                            <Link to="/service/services" className="custom-btn btn-big grad-style-ab">Découvrir nos métiers !</Link>
                                        </div>
                            </div>
                            <div className="img-container col-lg-6">
                                
                                <img src="../ITDS.jpg" alt="case-study" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>

                <div className="inner-page-banner light-bg">
                    <div className="container text-center">
                            <h1>Nos partenaires</h1>
                            <p>Nos partenaires sont au cœur de notre réussite. Chez IT DEV Solution, nous croyons en la puissance des collaborations et des synergies pour créer des opportunités et relever les défis du marché.</p>
                    </div>
                        <div className=" container-fluid pt-4 light-bg">
                           <div className="container">
                              <div className="row">
                                 <div className="col-12 col-md-6 light-bg py-5 px-5" id="parteners-home">
                                     <div>
                                        <h2 className="black-header ">Devenez notre partenaire privilégié et rejoignez notre réseau d'entreprises dynamiques et innovantes. </h2>
                                     </div>
                                     <div className="mt-4 ">
                                         <Link to="/parteners/parteners" type="button" className="custom-btn btn-big light-bg grad-style-ab"> Rejoignez-nous</Link>
                                     </div>
              
                                </div>
                                <div className=" bg-bluelight  col-md-5 col-lg-6 overflow-hidden">
                                <div className="overlay-img--cubeSm2 p-rc2 position-absolute b-6"></div>
                                   <img className="img-fit-cover b-6" src="./partenaire.png" alt="heroSectionImg" />
                                </div>
                            </div>
                       </div>
                </div>
                    
            </div>
 
                 
                    

                    
                
    <div className="container-fluid p-0 overflow-hidden bg-brand-primay dark-bg align-items-center">
      <div className="row">
        <div className="col-12 col-md-6">
          <img
            className="img-fit-cover b-6"
            src="./image-06.jpg"
            alt=""
            srcSet=""
          />
        </div>
        <div className="col-12 col-md-6 dark-bg " id="career-home">
          <h1 className="w-100">
         Façonnez Votre Avenir avec Nous : Découvrez de Nouvelles Opportunités et Rejoignez notre Équipe !
          </h1>
          <p className="text-justify">Notre portail de carrières offre un environnement stimulant pour les esprits ambitieux à la recherche de défis passionnants et de possibilités d'épanouissement professionnel. Nous invitons les talents prometteurs à rejoindre notre équipe et à contribuer à notre vision d'excellence et d'innovation.</p>
          <Link to="/Espace Carriére" className="custom-btn btn-big grad-style-ab">
            Voir les offres d'emploi
          </Link>
        </div>
      </div>
    </div>
         <Contact1/>
            </>
            
            
 )
    }
}