import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Parteners extends Component {
    render = () => {
        return (
            <div className="body-bg-style-3 inner-page">
                <div className="page-wrapper">
                    <svg className="bg-shape inner-page-shape-banner-right reveal-from-right move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_01" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_01)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>

                    <svg className="bg-shape inner-page-shape-banner-left reveal-from-left move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_02)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>

                    <section className="bg-bread">
                    <div className="bg-banner">
                        <div className="title-breadcaumb-left">
                            <h1>Partenariat</h1>
                            <h6>"IT Dev Solution : Votre Partenaire Numérique de Confiance. Bâtissons l'Avenir Numérique Ensemble."</h6>
                             <p className="text-justify"> IT Dev Solution, s'engage à cultiver des relations stratégiques propices à l'innovation et à la croissance. Avec une approche axée sur la collaboration et l'excellence, nous visons à créer des synergies durables qui repoussent les limites de la transformation numérique</p>  		
                            
                            <Link to="" className="button-header">Contactez-Nous</Link>
                        </div>
                        <div className="overlay-img--cubeLg1 position-absolute b-6"></div>
                        <div className="imagebreadcrumb-right ">
                        <img src="../partenaire.png" alt="Image 1"/>
                        </div>
                    </div>
                    </section>
                    <div className="inner-page-banner">
                    <div className="container text-center">
                        <p>Chez IT Dev Solution, nous sommes convaincus que la collaboration est essentielle pour réussir la transformation digitale des entreprises et administrations nationales. C'est pourquoi nous souhaitons collaborer avec des partenaires qui partagent nos valeurs et notre expertise.</p>
                            <h1>Nos valeurs</h1>
                    </div>
                </div>
                        
                <div className="containerpartener">

                     <div className="columnpartener">
                         <img src="../partenair/Innovation.png" alt="icon Innovation"/>
                         <h1>Innovation </h1>
                         <p>Nous nous engageons à fournir des solutions innovantes qui répondent aux besoins de nos clients.</p>
                     </div>

                     <div className="columnpartener">
                         <img src="../partenair/qualite.png" alt="icon Qualité"/>
                         <h1>Qualité</h1>
                         <p>Nous nous engageons à fournir des services de qualité, conformes aux normes les plus élevées.</p>
                    </div>

                    <div className="columnpartener">
                          <img src="../partenair/professionnalisme.png" alt="icon Qualité"/>
                          <h1>Professionnalisme</h1>
                          <p>Nous nous engageons à fournir un service professionnel et à la hauteur des attentes de nos clients.</p>
                    </div>
                </div>
                <div className="inner-page-partners">
                
                <div className="inner-page-banner">
                        <div className="container text-center">
                            <h2>Nos domaines d’expertise </h2>                            
                        </div>
                    </div>
                </div>
    <div className="flip-box-wrap">
       <div className="flip-box">
            <div className="flip-box-inner">
                <div className="flip-box-front">
               
                   <div className="flip-box-front-SI"> 
                    <h2>Systèmes d'information</h2>
                    </div>
                </div>
                <div className="flip-box-back">
                    <p>"IT DEV SOLUTION" offre une vaste gamme d'expertises pour répondre aux besoins croissants en systèmes d'information. Notre équipe talentueuse et expérimentée s'engage à fournir des solutions innovantes et personnalisées pour les entreprises de toutes tailles.</p>
                </div>
            </div>
        </div>
        <div className="flip-box">
            <div className="flip-box-inner">
                <div className="flip-box-front">
                    <div className="flip-box-front-devweb"> 
                      <h2>Développement web et mobile</h2>
                    </div>
                </div>
                <div className="flip-box-back">
                    <p>"IT DEV SOLUTION" propose une large palette de compétences pour répondre à l'évolution constante du secteur du développement web et mobile. Notre équipe hautement qualifiée et chevronnée s'engage à élaborer des solutions novatrices et sur mesure.</p>
                </div>
            </div>
        </div>
        <div className="flip-box">
            <div className="flip-box-inner">
                <div className="flip-box-front">
                   <div className="flip-box-front-IA"> 
                      <h2>Intelligence artificielle</h2>
                    </div>
                </div>
                <div className="flip-box-back">
                    <p>"IT DEV SOLUTION" excelle dans le domaine de l'intelligence artificielle, en proposant une large gamme d'expertises pour répondre aux besoins en constante évolution dans ce domaine en pleine expansion.</p>
                </div>
            </div>
        </div>
        <div className="flip-box">
            <div className="flip-box-inner">
                <div className="flip-box-front">
                    <div className="flip-box-front-BD"> 
                      <h2>Big Data</h2>
                    </div>
                </div>
                <div className="flip-box-back">
                    <p>"IT DEV SOLUTION" se distingue dans le domaine du Big Data, en proposant une variété d'expertises pour répondre aux besoins croissants liés à la gestion et à l'analyse de données massives. </p>
                </div>
            </div>
        </div>
        <div className="flip-box">
            <div className="flip-box-inner">
                <div className="flip-box-front">
                   <div className="flip-box-front-CS"> 
                      <h2>Cyber Sécurité</h2>
                    </div>
                    
                </div>
                <div className="flip-box-back">
                    <p>"IT DEV SOLUTION" excelle dans le domaine de la cybersécurité, offrant une large gamme d'expertises pour répondre aux besoins croissants en matière de sécurité informatique. </p>
                </div>
            </div>
        </div>
        
    </div>


                </div>
            </div>
        )
    }
}
