import React, { Component } from "react";

export default class Contact1 extends Component {
    render = () => {
        return (
            

            <div className="wrapperco">
            <div className="containerpartener">
                   <div className="columncontacthome col-lg-4">
                        <img src="../icon-contact/adresse.png" alt="icon adresse"/>
                         
                         <h1>Adresse</h1>
                         <p>Tour n° 1 Hamma, 170 Rue Hassiba Benbouali, Alger</p>
                    </div>
                     <div className="columncontacthome col-lg-4">
                       <img src="../icon-contact/courrier.png" alt="icon email"/>
                         <h1>Email </h1>
                         <p>contact@itdevsolution.dz</p>
                     </div>

                    

                    <div className="columncontacthome col-lg-4">
                    <img src="../icon-contact/telephoner.png" alt="icon email"/>
                    <h1>Téléphone </h1>
                          <p>023 82 43 10 Fax: 023 82 41 79 </p>
                    </div>
                </div>
        <div className="containerco">
            
			<div className="titleco">
					
				<h5>Contacter-nous maintenant !</h5>
                    <p className="text-justify">
                    "Nous sommes impatients d'avoir de vos nouvelles ! N'hésitez pas à nous contacter pour toute demande d'information, de service ou de collaboration. Notre équipe dévouée est là pour répondre à vos questions et vous aider de la meilleure façon possible. Remplissez simplement le formulaire ou envoyez-nous un e-mail à contact@itdevsolution.dz. Nous vous répondrons dans les plus brefs délais. Merci de votre intérêt pour notre entreprise." 
					</p>

                    <ul className="social-icons popup">
                                        <li>
                                            <a href="#instagram" rel="noreferrer" title="instageam">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#twitter" rel="noreferrer" title="twitter">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#google" rel="noreferrer" title="google">
                                                <i className="fab fa-google"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#github" rel="noreferrer" title="github">
                                                <i className="fab fa-github"></i>
                                            </a>
                                        </li>
                        </ul>
                        <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102344.29375848176!2d2.8413661422826566!3d36.716336163465904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fb29a25e57581%3A0x5c591f7331bef151!2sFonds%20National%20d&#39;Investissement!5e0!3m2!1sfr!2sdz!4v1697376655324!5m2!1sfr!2sdz" ></iframe></p>
            </div>

                

				<form>
					<div>
                    

						<input type="text" placeholder="Nom & Prénom" />
					</div>
					<div>
						<h6></h6>
						<input type="text" placeholder="Entreprise" />
					</div>
					<div>
						<h6></h6>
						<input type="text" placeholder="E-mail" />
					</div>
					<div>
						<h6></h6>
						<textarea placeholder="Votre Message" />
					</div>
					<div>
						<button onClick={(e) => e.preventDefault()}>
							Envoyer
						</button>
					</div>
				</form>
			</div>
		</div>

        );
    }
    }