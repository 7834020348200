import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Contact1 from "../../contact_for/Contact1";

export default class Intégration extends Component {
    render = () => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            delay: 2555 ^ 2
        };
        return (
            <div className="body-bg-style-1 inner-page">
                <div className="page-wrapper">
                  <section className="bg-bread">
                    <div className="bg-banner">
                        <div className="title-breadcaumb-left">
                            <h1>Intégration de solutions</h1>
                            <h6>Construisons Votre Écosystème Numérique Cohérent</h6>
                             <p className="text-justify">Nous vous aidons à renforcer votre efficacité opérationnelle et à créer un écosystème numérique robuste, capable de répondre aux exigences les plus complexes de votre entreprise.</p>  		
                            
                            <Link to="" className="button-header">Contactez-Nous</Link>
                        </div>
                        <div className="imagebreadcrumb-right">
                        <div className="overlay-img--cubeLg1 position-absolute b-6"></div>

                        <img src="../../solutions/Intégration-de-solutions.png" alt="Image 1"/>
                        </div>
                    </div>
                    </section>
                

                    <section className="our-process section-padding">
                        <div className="container">
                            <h5 className="text-center">Notre méthodologie</h5>
                           

                            <div className="service-process-tab">
                                <ul className="nav nav-tabs service-tab-nav" id="service-tab-nav">
                                    <li className="nav-item">
                                        <Link to="#process-tab-1" className="nav-link active" id="process-nav-1" data-bs-toggle="tab" role="tab" aria-controls="process-tab-1"
                                            aria-selected="true">
                                            <i className="ml-edu-86-People"></i>
                                            <span>Implementation de logiciels</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#process-tab-2" className="nav-link" id="process-nav-2" data-bs-toggle="tab" role="tab" aria-controls="process-tab-2" aria-selected="true">
                                            <i className="ml-des-19-Transform-shape-graphics-design-tool-interface"></i>
                                            <span>Paramétrage et customisation</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#process-tab-3" className="nav-link" id="process-nav-3" data-bs-toggle="tab" role="tab" aria-controls="process-tab-3" aria-selected="true">
                                            <i className="ml-tec-9-check-mark-yes-computer-desktop-monitor-laptop"></i>
                                            <span>Interfaçage</span>
                                        </Link>
                                    </li>
                                </ul>
                                <div className="tab-content service-tab-content" id="service-tab">
                                    <div className="tab-pane fade show active" id="process-tab-1" role="tabpanel" aria-labelledby="process-nav-1">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-5 text-lg-right">
                                                <img src="/Conseil/Implementation.png" alt="process-image1" className="img-fluid" />
                                            </div>

                                            <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
                                                <h4>Implementation de logiciels du marché</h4>
                                                <p className="text-justify">Nous nous spécialisons dans l'implémentation fluide de solutions logicielles éprouvées du marché, adaptées spécifiquement à vos besoins opérationnels. Notre approche méthodique garantit une transition sans heurts vers de nouvelles plateformes, améliorant ainsi l'efficacité de vos opérations et optimisant vos processus métier.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="process-tab-2" role="tabpanel" aria-labelledby="process-nav-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-5 text-lg-right">
                                                <img src="/Conseil/Paramétrage .png" alt="process-image2" className="img-fluid" />
                                            </div>

                                            <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
                                                <h4>Paramétrage et customisation</h4>
                                                <p className="text-justify">En comprenant les particularités de votre entreprise, nous personnalisons et configurons soigneusement les solutions logicielles pour répondre précisément à vos exigences spécifiques. Notre équipe experte s'engage à ajuster et à adapter les fonctionnalités pour garantir une expérience utilisateur optimale et un alignement parfait avec vos objectifs métier.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="process-tab-3" role="tabpanel" aria-labelledby="process-nav-3">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-5 text-lg-right">
                                                <img src="/Conseil/Interfaçage avec systèmes existants.png" alt="process-image3" className="img-fluid" />
                                            </div>
                                            

                                            <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
                                                <h4>Interfaçage avec systèmes existants </h4>
                                                <p className="text-justify">
                                                Notre expertise en interfaçage permet une intégration transparente entre vos systèmes existants et les nouvelles solutions mises en place. Nous nous assurons que les flux de données sont cohérents et harmonisés, optimisant ainsi la communication entre vos différentes plateformes et assurant une collaboration fluide au sein de votre infrastructure technologique.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <section className="case-study small-agency-case-study">
              
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 order-lg-2">
                                <Slider className="case-study-slider" {...settings}>
                                    <div className="item">
                                        <h2>Méthodologie agile</h2>
                                        <p className="text-justify">La Méthodologie Agile est au cœur de notre approche de travail chez ITDS. En privilégiant la flexibilité et la collaboration continue, nous nous engageons à offrir des solutions évolutives et innovantes à nos clients. Grâce à des cycles de développement itératifs, nous encourageons la transparence et la participation active des parties prenantes tout au long du processus de développement. En adoptant les principes de l'Agilité, nous sommes en mesure de nous adapter rapidement aux changements et de répondre de manière efficace aux besoins changeants de nos clients, assurant ainsi une satisfaction optimale et des résultats de qualité supérieure. </p>
                                       
                                    </div>

                                    <div className="item">
                                        <h2>La qualité</h2>
                                        <p className="text-justify">Chez ITDS, la qualité est une priorité essentielle. En mettant l'accent sur des normes rigoureuses et des processus de contrôle de qualité robustes, nous nous engageons à fournir des solutions fiables et durables à nos clients. Nous nous efforçons continuellement d'améliorer nos services en intégrant les meilleures pratiques et en mettant en œuvre des tests approfondis pour garantir des résultats de premier ordre. Notre engagement envers l'excellence se reflète dans chaque aspect de notre travail, assurant ainsi la satisfaction totale de nos clients et renforçant notre réputation d'excellence dans l'industrie des services numériques. </p>
                                       
                                    </div>

                                    <div className="item">
                                        <h2>La satisfaction</h2>
                                        <p className="text-justify">Nous accordons une importance primordiale à la satisfaction de nos clients chez ITDS. En écoutant attentivement leurs besoins et en travaillant en étroite collaboration avec eux tout au long du processus, nous nous efforçons de fournir des solutions qui dépassent leurs attentes. Notre engagement envers un service client exceptionnel se reflète dans notre approche axée sur la communication, la transparence et la réactivité. En comprenant pleinement les objectifs et les exigences de nos clients, nous nous efforçons de garantir une expérience positive et gratifiante à chaque étape de notre collaboration. Notre objectif ultime est de créer des relations durables basées sur la confiance, la fiabilité et la réussite mutuelle.</p>
                                       
                                    </div>

                                    
                                </Slider>
                                <div className="btn-container">
                                            <Link to="/service/services" className="custom-btn btn-big grad-style-ab">Découvrir nos métiers !</Link>
                                        </div>
                            </div>
                            <div className="img-container col-lg-6">
                                <img src="/ITDS.jpg" alt="case-study" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                </div>
                <Contact1/>
            </div>
        )
    }
}
