import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Contact1 from "../../../contact_for/Contact1";

export default class WebsiteDevelopment extends Component {
    render = () => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            delay: 2555 ^ 2
        };
        return (
            <div className="body-bg-style-1 inner-page">
                <div className="page-wrapper">
                    <section className="bg-bread">
                        <div className="bg-banner">
                            <div className="title-breadcaumb-left">
                                <h1>Développement sur-mesure</h1>
                                <h6>Construisons Votre Futur Digital sur Mesure et Façonnons Votre Présence en Ligne</h6>
                                <p className="text-justify">Nous sommes des experts en développement sur-mesure, concevant des applications adaptées à vos besoins spécifiques. Nous vous offrons des solutions sur mesure qui propulsent votre entreprise vers l'avenir numérique</p>

                                <Link to="" className="button-header">Contactez-Nous</Link>
                            </div>
                            <div className="imagebreadcrumb-right">
                            <div className="overlay-img--cubeLg1 position-absolute b-6"></div>

                                <img src="/solutions/Développement-sur-mesure.png" alt="developpement sur mesure" />

                            </div>
                        </div>
                    </section>


                    <section className="our-process section-padding">
                        <div className="container">
                            <h5 className="text-center">Notre méthodologie</h5>


                            <div className="service-process-tab">
                                <ul className="nav nav-tabs service-tab-nav" id="service-tab-nav">
                                    <li className="nav-item">
                                        <Link to="#process-tab-1" className="nav-link active" id="process-nav-1" data-bs-toggle="tab" role="tab" aria-controls="process-tab-1"
                                            aria-selected="true">
                                            <i className="ml-edu-86-People"></i>
                                            <span>Conception</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="#process-tab-2" className="nav-link" id="process-nav-2" data-bs-toggle="tab" role="tab" aria-controls="process-tab-2" aria-selected="true">
                                            <i className="ml-des-19-Transform-shape-graphics-design-tool-interface"></i>
                                            <span>Développement web/mobile</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="#process-tab-3" className="nav-link" id="process-nav-3" data-bs-toggle="tab" role="tab" aria-controls="process-tab-3" aria-selected="true">
                                            <i className="ml-tec-9-check-mark-yes-computer-desktop-monitor-laptop"></i>
                                            <span> Intelligence Artificielle</span>
                                        </Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="#process-tab-4" className="nav-link" id="process-nav-4" data-bs-toggle="tab" role="tab" aria-controls="process-tab-3" aria-selected="true">
                                            <i className="ml-tec-9-check-mark-yes-computer-desktop-monitor-laptop"></i>
                                            <span>Business Intelligence</span>
                                        </Link>
                                    </li>

                                </ul>
                                <div className="tab-content service-tab-content" id="service-tab">


                                    <div className="tab-pane fade show active" id="process-tab-1" role="tabpanel" aria-labelledby="process-nav-1">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-5 text-lg-right">
                                                <img src="/Conseil/applications.png" alt="process-image1" className="img-fluid" />
                                            </div>

                                            <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
                                                <h4>Conception d'applications métier selon les besoins spécifiques</h4>
                                                <p className="text-justify">Notre équipe dédiée travaille en étroite collaboration avec votre entreprise pour concevoir des applications sur mesure qui répondent précisément à vos besoins opérationnels uniques. En comprenant en profondeur vos processus métier, nous créons des solutions logicielles adaptées qui optimisent vos opérations et améliorent votre efficacité globale.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="process-tab-2" role="tabpanel" aria-labelledby="process-nav-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-5 text-lg-right">
                                                <img src="/Conseil/web-mob-app.png" alt="process-image2" className="img-fluid" />
                                            </div>

                                            <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
                                                <h4>Développement web et mobile</h4>
                                                <p className="text-justify">Grâce à notre expertise en développement web et mobile, nous concevons des plateformes interactives et conviviales qui offrent une expérience utilisateur exceptionnelle. Notre approche centrée sur l'utilisateur garantit des interfaces intuitives et réactives, permettant à votre entreprise de rester compétitive dans un monde numérique en constante évolution.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="process-tab-3" role="tabpanel" aria-labelledby="process-nav-3">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-5 text-lg-right">
                                                <img src="/Conseil/big-data-ia.png" alt="process-image3" className="img-fluid" />
                                            </div>

                                            <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
                                                <h4>Technologies innovantes : IA et big data </h4>
                                                <p className="text-justify">
                                                    Nous sommes à l'avant-garde des technologies émergentes telles que l'Intelligence Artificielle et le Big Data. En exploitant ces technologies avancées, nous transformons les données en insights stratégiques, permettant à votre entreprise de prendre des décisions éclairées et de rester agile dans un environnement commercial en évolution rapide.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="process-tab-4" role="tabpanel" aria-labelledby="process-nav-4">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-lg-5 text-lg-right">
                                                <img src="/Conseil/BI.png" alt="process-image2" className="img-fluid" />
                                            </div>

                                            <div className="col-lg-6 offset-lg-1 text-center text-lg-left">
                                                <h4>Business Intelligence :</h4>
                                                <p className="text-justify"> Notre expertise en Business Intelligence vous donne une vision claire de vos opérations commerciales. En collectant, analysant et visualisant vos données commerciales, nous vous aidons à prendre des décisions plus éclairées et à identifier de nouvelles opportunités de croissance. Notre approche personnalisée vous permet de transformer vos données en un avantage concurrentiel significatif.
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="case-study small-agency-case-study">

                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 order-lg-2">
                                    <Slider className="case-study-slider" {...settings}>
                                    <div className="item">
                                        <h2>Méthodologie agile</h2>
                                        <p className="text-justify">La Méthodologie Agile est au cœur de notre approche de travail chez ITDS. En privilégiant la flexibilité et la collaboration continue, nous nous engageons à offrir des solutions évolutives et innovantes à nos clients. Grâce à des cycles de développement itératifs, nous encourageons la transparence et la participation active des parties prenantes tout au long du processus de développement. En adoptant les principes de l'Agilité, nous sommes en mesure de nous adapter rapidement aux changements et de répondre de manière efficace aux besoins changeants de nos clients, assurant ainsi une satisfaction optimale et des résultats de qualité supérieure. </p>
                                       
                                    </div>

                                    <div className="item">
                                        <h2>La qualité</h2>
                                        <p className="text-justify">Chez ITDS, la qualité est une priorité essentielle. En mettant l'accent sur des normes rigoureuses et des processus de contrôle de qualité robustes, nous nous engageons à fournir des solutions fiables et durables à nos clients. Nous nous efforçons continuellement d'améliorer nos services en intégrant les meilleures pratiques et en mettant en œuvre des tests approfondis pour garantir des résultats de premier ordre. Notre engagement envers l'excellence se reflète dans chaque aspect de notre travail, assurant ainsi la satisfaction totale de nos clients et renforçant notre réputation d'excellence dans l'industrie des services numériques. </p>
                                       
                                    </div>
                                    <div className="item">
                                        <h2>La satisfaction</h2>
                                        <p className="text-justify">Nous accordons une importance primordiale à la satisfaction de nos clients chez ITDS. En écoutant attentivement leurs besoins et en travaillant en étroite collaboration avec eux tout au long du processus, nous nous efforçons de fournir des solutions qui dépassent leurs attentes. Notre engagement envers un service client exceptionnel se reflète dans notre approche axée sur la communication, la transparence et la réactivité. En comprenant pleinement les objectifs et les exigences de nos clients, nous nous efforçons de garantir une expérience positive et gratifiante à chaque étape de notre collaboration. Notre objectif ultime est de créer des relations durables basées sur la confiance, la fiabilité et la réussite mutuelle.</p>
                                       
                                    </div>


                                    </Slider>
                                    <div className="btn-container">
                                        <Link to="/service/services" className="custom-btn btn-big grad-style-ab">Découvrir nos métiers !</Link>
                                    </div>
                                </div>
                                <div className="img-container col-lg-6">
                                    <img src="/ITDS.jpg" alt="case-study" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Contact1/>
            </div>
        )
    }
}
