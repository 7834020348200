import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Isotope from "isotope-layout";
import Contact1 from "../../contact_for/Contact1";

export default class Services extends Component {
    componentDidMount(): void {
        const grid = document.querySelector(".grid") as HTMLElement;
        let iso = new Isotope(grid, {
            itemSelector: ".grid-item",
            layoutMode: "masonry",
            percentPosition: true,
        });

        $(".filter-button-group").each(function (i, buttonGroup) {
            const $buttonGroup = $(buttonGroup);
            $buttonGroup.on("click", ".filter-button", function () {
                $buttonGroup.find(".is-checked").removeClass("is-checked");
                $(this).addClass("is-checked");

                const filterValue = $(this).attr("data-filter");
                iso.arrange({
                    filter: filterValue
                })
            });
        });
    };

    render = () => {
        return (
            <div className="body-bg-style-3 inner-page">
                <div className="page-wrapper">
                    <svg className="bg-shape inner-page-shape-banner-right reveal-from-right move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_01" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_01)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>

                    <svg className="bg-shape inner-page-shape-banner-left reveal-from-left move-image" xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                        <path fillRule="evenodd" fill="url(#PSgrad_02)"
                            d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z" />
                    </svg>
                    <section className="bg-bread">
                    <div className="bg-banner">
                        <div className="title-breadcaumb-left">
                            <h1>Services</h1>
                            <h6>Simplifiez votre vie professionnelle avec nos services</h6>
                             <p>Nous sommes là pour vous accompagner avec des services de qualité à votre portée. Faites confiance à notre expertise et bénéficiez d'une expérience exceptionnelle</p>  		
                            
                            <Link to="" className="button-header">Contactez-Nous</Link>
                        </div>
                        <div className="imagebreadcrumb-right">
                        <div className="overlay-img--cubeLg1 position-absolute b-6"></div>

                        <img src="../../solutions/services.png" alt="Image 1"/>
                        </div>
                    </div>
                    </section>


                 

                    <section className="featured-projects section-padding">
                        <div className="container">
                            <h1 className="text-center portfolio">TOUS LES SERVICES</h1>
                            <div className="featured-project-showcase text-center">
                                <div className="row equalHeightWrapper">
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                    <Link to="/Conseil/et/Intégration" target="_blank" rel="noreferrer" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Conseil.png"
                                                    alt="service1" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">Consulting</span>
                                                Conseil
                                            </h5>
                                        </Link>
                                    </div>
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/Développement/sur/mésure" className="featured-content-block content-block" 
                                         data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="/solutions/Développement-sur-mesure.png"
                                                    alt="project2" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">Web</span>
                                                Développement sur-mesure
                                            </h5>
                                        </Link>
                                    </div>
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/Intégration" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Intégration-de-solutions.png"
                                                    alt="project3" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">Web / Desktop</span>
                                                Intégration de solutions
                                            </h5>
                                        </Link>
                                    </div>

                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/Entrepot" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Entrepôt-de-données-et-reporting.png"
                                                    alt="service1" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">BI</span>
                                                Entrepôt de données et reporting
                                            </h5>
                                        </Link>
                                    </div>
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/Infogérance" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Infogérance.png"
                                                    alt="service1" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">IT</span>
                                                Infogérance
                                            </h5>
                                        </Link>
                                    </div>
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/Hébergement" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Hébergement.png"
                                                    alt="service1" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">Cloud</span>
                                                Hébergement
                                            </h5>
                                        </Link>
                                    </div>
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/Sécurité" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Sécurité.png"
                                                    alt="service1" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">CyberSécurité</span>
                                                Sécurité
                                            </h5>
                                        </Link>
                                    </div>
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/Conformité" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Conformité.png"
                                                    alt="service1" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">SI</span>
                                                Conformité
                                            </h5>
                                        </Link>
                                    </div>
                                    <div className="grid-item col-md-6 col-lg-4 ">
                                        <Link to="/service/CentresAppels" className="featured-content-block content-block" 
                                            data-bs-target="#featured-project-modal">
                                            <div className="img-container">
                                                <img src="../../solutions/Centres d'appels.png"
                                                    alt="service1" className="img-fluid" />
                                            </div>
                                            <h5 className="equalHeight">
                                                <span className="content-block__sub-title">Service desk </span>
                                                Centres d'appels
                                            </h5>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Contact1/>
               
            </div>
        )
    }
}
