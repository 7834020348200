import React, { Component } from "react";
import { Link } from "react-router-dom";
import { fadeToggle, fadeOut, slideToggle, slideUp } from "../Animations";
import { contact } from "../../api/contact";
import { RiArrowDropDownLine } from 'react-icons/ri';

import CustomError from "../../api/error/Error";

export default class Header extends Component {
    state = {
        fname: "",
        email: "",
        phone: "",
        website: "",
        text: "",
        statusMessage: "* Requis",
        statusMessageClass: ""
    };

    componentDidMount = () => {
        window.addEventListener("scroll", this.handleScroll);
        document.addEventListener("click", this.handleClick);
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.handleScroll);
        document.removeEventListener("click", this.handleClick);
    }

    handleScroll = () => {
        const navbar = document.querySelector(".navbar")!;
        const shouldScroll = window.scrollY >= 100;
        navbar.classList.toggle("scrolled", shouldScroll);
    };

    handleClick = (e: MouseEvent) => {
        const navbar = document.getElementById("navbarNav")!;
        const navMenu = Array.from(document.querySelectorAll(".navbar-nav li"));
        const dropdownMenu = document.querySelector(".custom-dropdown-menu") as HTMLElement;
        const shouldHideDropdown =
            !(
                navMenu.includes(e.target as Element) ||
                Array.from(navMenu).some((el) =>
                    el.querySelector("li, a")!.contains(e.target as Element)
                )
            );
        const isOpenNav = navbar.classList.contains("show") && !navbar.classList.contains("removing");
        const isOpenDropdown = dropdownMenu.offsetWidth > 0 || dropdownMenu.offsetHeight > 0;

        if (shouldHideDropdown) {
            const animationType = window.innerWidth < 991 ? slideUp : fadeOut;
            if (isOpenDropdown) {
                animationType(dropdownMenu, 200);
            } else if (isOpenNav) {
                navbar.classList.add("removing");
                document.querySelector(".navbar .navbar-toggler")!.classList.remove("change");
                animationType(navbar).then(() => {
                    navbar.classList.remove("show", "removing");
                    navbar.removeAttribute("style");
                });
            }
        }
    };

    handleCustomTogglerIcon = () => {
        if (!document.getElementById("navbarNav")!.classList.contains("removing")) {
            document.querySelector(".navbar .navbar-toggler")!.classList.toggle("change");
        }
    };

    handleDropdown = () => {
        const dropdownMenu = document.querySelector(".custom-dropdown-menu") as HTMLElement;
        window.innerWidth < 991 ?
            slideToggle(dropdownMenu, 200) :
            fadeToggle(dropdownMenu, 200);
    };

    handleCloseDropdown = () => {
        const navbar = document.getElementById("navbarNav")!;
        const isNavOpen = navbar.classList.contains("show");
        const dropdownMenu = document.querySelector(".custom-dropdown-menu") as HTMLElement;

        window.innerWidth < 991 ? slideToggle(dropdownMenu, 200) : fadeToggle(dropdownMenu, 200);
        
        if (isNavOpen) {
            navbar.classList.remove("show");
            document.querySelector(".navbar .navbar-toggler")!.classList.remove("change");
        }
    };

    handleCloseNav = () => {
        const navbar = document.getElementById("navbarNav")!;
        const isNavOpen = navbar?.classList.contains("show");

        if (isNavOpen) {
            navbar.classList.remove("show");
            document.querySelector(".navbar .navbar-toggler")!.classList.remove("change");
        }
        fadeOut(document.querySelector(".custom-dropdown-menu")!);
    };

    handleGetQuote = () => {
        window.location.hash = "Contact";
        this.handleCloseNav();
    };

    handleContact = async (e: React.MouseEvent) => {
        e.preventDefault();

        const {fname, email, phone, website, text } = this.state;

        const input: Array<HTMLInputElement> = [
            document.getElementById("fname") as HTMLInputElement,
            document.getElementById("email") as HTMLInputElement,
            document.getElementById("phone") as HTMLInputElement,
            document.getElementById("website") as HTMLInputElement,
            document.getElementById("message") as HTMLInputElement,
        ];

        input.forEach((el) => el.classList.remove("error-input"));

        try {
            const res = await contact(fname, email, phone, website, text);

            const { error, field, message, code } = res;

            if (error && code) {
                field.forEach((el) => {
                    const element = input.find((element) => element.id === el)!;
                    element.classList.add("error-input");
                });
                this.setState({
                    statusMessage: message,
                    statusMessageClass: "error-message"
                });
            } else {
                field.forEach((el) => {
                    const element = input.find((element) => element.id === el)!;
                    element.classList.add("succcess-input", "readonly");
                    element.setAttribute("readonly", "true");
                });
                this.setState({
                    statusMessage: message,
                    statusMessageClass: "success-message"
                });
                if (e.target instanceof Element) {
                    e.target.remove();
                }
            }
        } catch (error) {
            if (error instanceof CustomError) {
                error.field.forEach((el) => {
                    const element = input.find((element) => element.id === el)!;
                    element.classList.add("error-input");
                });
                this.setState({
                    statusMessage: error.message,
                    statusMessageClass: "error-message"
                });
            }
        }
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, field: string) => {
        this.setState({ [field]: e.target.value });
    };

    renderNavItem = (to: string, label: string) => {
        return (
            <li className="nav-item">
                <Link className="nav-link" to={to} onClick={this.handleCloseNav}>{label}</Link>
            </li>
        );
    };

    renderDropdownItem = (to: string, label: string) => {
        return (
            <Link className="dropdown-item" to={to} onClick={this.handleCloseDropdown}>{label}</Link>
        );
    };

    renderQuoteNavItem = (to: string) => {
        return (
            <li className="nav-item">
                <Link className="nav-link custom-btn btn-mid grad-style-cd" to={to} data-bs-toggle="modal" data-bs-target="#get-a-quote-modal" onClick={this.handleGetQuote}>Contactez-nous</Link>
            </li>
        );
    };

    render = () => {
        const { fname,email, phone, website, text, statusMessage, statusMessageClass } = this.state;

        return (
            <>
       
          <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img src="../lg.png" alt="Brand Logo" className="img-fluid" />
                        </Link>
                        <button className="navbar-toggler" type="button" onClick={this.handleCustomTogglerIcon} data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            <span className="custom-toggler-icon bar1"></span>
                            <span className="custom-toggler-icon bar2"></span>
                            <span className="custom-toggler-icon bar3"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-md-auto typo-color-c align-items-center">
                                {this.renderNavItem("/", "Accueil")}
                                {this.renderNavItem("/A propos", "À propos")}

                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-opener" onClick={this.handleDropdown} to="/service/services">
                                       Services
                                       <RiArrowDropDownLine />
                                    </Link>
                                    <div className="custom-dropdown-menu">
                                        {this.renderDropdownItem("/Conseil/et/Intégration", "Conseil")}
                                        {this.renderDropdownItem("/service/Développement/sur/mésure", "Développement sur_mesure")}
                                        {this.renderDropdownItem("/service/Intégration", "Intégration de solutions")}
                                        {this.renderDropdownItem("/service/Entrepot", "Entrepot de données et reporting")}
                                        {this.renderDropdownItem("/service/Infogérance", "Infogérance")}
                                        {this.renderDropdownItem("/service/Hébergement", "Hébergement")}
                                        {this.renderDropdownItem("/service/Sécurité", "Sécurité")}
                                        {this.renderDropdownItem("/service/Conformité", "Conformité")}
                                        {this.renderDropdownItem("/service/CentresAppels", "CentresAppels")}
                                    </div>
                                </li>
                                {this.renderNavItem("/parteners", "Partenariat")}
                                {this.renderNavItem("/EspaceCarrière", "Espace carrière")}
                                {this.renderQuoteNavItem("/#Contact")}
                            </ul>
                        </div>
                    </div>
                </nav>
                
                <div className="modal fade get-a-quote-modal" id="get-a-quote-modal" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" id="button-quote">
                                    <i className="ml-symtwo-24-multiply-cross-math">X
                                    </i>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="contact-form-wrapper">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="contact-wrapper contact-page-form-wrapper">
                                                    <div className="form-wrapper">
                                                        <h3>Formulaire de contact</h3>
                                                        <form className="contact-form">
                                                            <div className="row">
                                                                <div className="col-md-12 col-lg-6">
                                                                    <input
                                                                        type="text"
                                                                        name="fname"
                                                                        id="fname"
                                                                        placeholder="Entreprise  *"
                                                                        value={fname}
                                                                        onChange={(e) => this.handleInputChange(e, "fname")}
                                                                    />
                                                                </div>
                                                              

                                                                <div className="col-md-12 col-lg-6">
                                                                    <input
                                                                        type="email"
                                                                        name="email"
                                                                        id="email"
                                                                        placeholder="Email *"
                                                                        value={email}
                                                                        onChange={(e) => this.handleInputChange(e, "email")}
                                                                    />
                                                                </div>

                                                                <div className="col-md-12 col-lg-6">
                                                                    <input
                                                                        type="text"
                                                                        name="phone"
                                                                        id="phone"
                                                                        placeholder="Numéro de téléphone"
                                                                        value={phone}
                                                                        onChange={(e) => this.handleInputChange(e, "phone")}
                                                                    />
                                                                </div>

                                                                <div className="col-md-12 col-lg-6">
                                                                    <input
                                                                        type="text"
                                                                        name="website"
                                                                        id="website"
                                                                        placeholder="Website"
                                                                        value={website}
                                                                        onChange={(e) => this.handleInputChange(e, "website")}
                                                                    />
                                                                </div>

                                                                <div className="col-md-12">
                                                                    <textarea
                                                                        name="message"
                                                                        id="message"
                                                                        placeholder="Votre message *"
                                                                        value={text}
                                                                        onChange={(e) => this.handleInputChange(e, "text")}
                                                                    ></textarea>
                                                                </div>
                                                                <div className="btn-wrapper">
                                                                    <p id="status-contact" className={statusMessageClass}>{statusMessage}</p>
                                                                    <button type="submit" id="submit"
                                                                        className="custom-btn btn-big grad-style-ef" onClick={(e) => this.handleContact(e)}>Envoyer</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="contact-info floating-contact-info">
                                                    <h5>Information de contact</h5>

                                                    <nav className="footer-nav">
                                    
                                    <p><span className="icon fa fa-phone"></span>&nbsp; <b>Tel:</b> 023 82 43 10 / <b>Fax: 023 82 41 79</b></p>
                                    <p><span className="icon fa fa-envelope"></span>&nbsp; contact@itdevsolution.dz</p>
                                    <p><span className="icon fa fa-home"></span> Tour n° 1 Hamma, 170 Rue Hassiba Benbouali, Alger</p>
                                    </nav>

                                                    <p className="address">
                                                        Envoyez nos un email
                                                        <a href="mailto:partenariat@itdevsolution.dz">partenariat@itdevsolution.dz</a>
                                                    </p>

                                                    <div className="social-icons-wrapper">
                                                        <p>Suivez-nous</p>
                                                        <ul className="social-icons popup">
                                                            <li>
                                                                <a href="#instagram" rel="noreferrer">
                                                                    <i className="fab fa-instagram"></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#twitter" rel="noreferrer">
                                                                    <i className="fab fa-twitter"></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#google" rel="noreferrer">
                                                                    <i className="fab fa-google"></i>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#github" rel="noreferrer">
                                                                    <i className="fab fa-github"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

          
            </>
        );
    };
};
