import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomError from "../../api/error/Error";
import { newsletter } from "../../api/newsletter";

export default class Footer extends Component {
    state = {
        fname: "",
        email: "",
        statusMessage: "",
        statusMessageClass: ""
    };

    handleRegisterNewsletter = async (e: React.MouseEvent) => {
        e.preventDefault();

        const { fname, email } = this.state;

        const input: Array<HTMLInputElement> = [
            document.getElementById("fname-newsletter") as HTMLInputElement,
            document.getElementById("email-newsletter") as HTMLInputElement
        ];

        input.forEach((el) => el.classList.remove("error-input"));

        try {
            const res = await newsletter(fname, email);

            const { error, field, message, code } = res;

            if (error && code) {
                field.forEach((el) => {
                    const element = input.find((element) => element.id === el)!;
                    element.classList.add("error-input");
                });
                this.setState({
                    statusMessage: message,
                    statusMessageClass: "error-message"
                });
            } else {
                field.forEach((el) => {
                    const element = input.find((element) => element.id === el)!;
                    element.classList.add("success-input", "readonly");
                    element.setAttribute("readonly", "true");
                });
                this.setState({
                    statusMessage: message,
                    statusMessageClass: "success-message"
                });
                if (e.target instanceof Element) {
                    e.target.remove();
                }
            }
        } catch (error) {
            if (error instanceof CustomError) {
                error.field.forEach((el) => {
                    const element = input.find((element) => element.id === el)!;
                    element.classList.add("error-input");
                });
                this.setState({
                    statusMessage: error.message,
                    statusMessageClass: "error-message"
                });
            }
        }
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        this.setState({ [field]: e.target.value });
    };

    renderNavItem = (to: string, label: string) => {
        return (
            <li>
                <Link to={to}>{label}</Link>
            </li>
        );
    };

    render = () => {
        const { fname, email, statusMessage, statusMessageClass } = this.state;

        return (
            <>
       
                <section className="newsletter">
                    <div className="container">
                        <h5 className="text-center">Rejoignez notre newsletter
                            <br />Recevez des conseils , offres , articles
                            <p id="status-newsletter" className={statusMessageClass}>{statusMessage}</p>
                        </h5>

                        <form className="newsletter-form">
                            <div className="row justify-content-between">
                                
                                <div className="col-md">
                                    <input
                                        type="text"
                                        name="email"
                                        id="email-newsletter"
                                        placeholder="Your Email Address"
                                        value={email}
                                        onChange={(e) => this.handleInputChange(e, "email")}
                                    />
                                </div>

                                <div className="col-md-auto">
                                    <a href="#newsletter" className="custom-btn btn-big grad-style-ef" onClick={(e) => this.handleRegisterNewsletter(e)}>Je m'inscris</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <footer className="small-agency-footer grey-bg">
                    <div className="footer-nav-wrapper">
                        <div className="container">
                            <div className="row">
                              
                            <div className="col-lg-4">
                                    <nav className="footer-nav">
                                        <h5>It Dev Solution</h5>
                                        <p className="text-justify" >ITDS est une entreprise de services numériques, filiale à 100 % du Fonds National d'Investissement (FNI). Notre mission est d'accompagner les entreprises et organismes algériens dans leur transformation digitale grâce à des solutions innovantes, sécurisées et conformes.</p>
                                        <ul className="social-icons popup">
                                        <li>
                                            <a href="#instagram" rel="noreferrer" title="instageam">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#twitter" rel="noreferrer" title="twitter">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#google" rel="noreferrer" title="google">
                                                <i className="fab fa-google"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#github" rel="noreferrer" title="github">
                                                <i className="fab fa-github"></i>
                                            </a>
                                        </li>
                                    </ul>
                                    </nav>
                                </div>

                                <div className="col-lg-4">
                                    <nav className="footer-nav service-nav">
                                        <h5>Services</h5>
                                        <ul className="footer-menu">                                   
                                            {this.renderNavItem("/Conseil/et/Intégration", "Conseil")}
                                            {this.renderNavItem("/service/Développement/sur/mésure", "Développement sur-mesure")}
                                            {this.renderNavItem("/service/Intégration", "Intégration de solutions")}
                                            {this.renderNavItem("/service/Sécurité", "Sécurité")}
                                            
                                        </ul>
                                    </nav>
                                    
                                </div>
                                
                               
                                 
                              

                                <div className="col-lg-4">
                                    <nav className="footer-nav">
                                        <h5>Contact</h5>
                                    
                                    <p><span className="icon fa fa-phone"></span>&nbsp; <b>Tel:</b> 023 82 43 10 / <b>Fax: 023 82 41 79</b></p>
                                    <p><span className="icon fa fa-envelope"></span>&nbsp; contact@itdevsolution.dz</p>
                                    <p><span className="icon fa fa-home"></span> Tour n° 1 Hamma, 170 Rue Hassiba Benbouali, Alger</p>
                                    </nav>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom">
                        <div className="container">
                            <p className="text-center">&copy; 2023-2024. All rights reserved by
                                <a href="/" target="_blank" rel="noopener"> IT DEV Solution</a></p>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}
