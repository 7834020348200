import React, { Component } from "react";
import AOS from "aos";
import { Link } from "react-router-dom";
import "./about.css"
import Contact1 from "../contact_for/Contact1";
export default class About extends Component {
    componentDidMount() {
        AOS.init({
            duration: 500,
            offset: 200,
            easing: "ease-in-out",
            once: true,
            delay: 100
        });
    };

    render = () => {
        return (
            <div className="body-bg-style-3 inner-page">
                <div className="page-wrapper">
                 

                <section className="bg-bread">
                    <div className="bg-banner">
                        <div className="title-breadcaumb-left">
                            <h1>A propos de IT DEV Solution</h1>
                            <h6>S'Engager Vers l'Excellence Numérique</h6>
                             <p className="text-justify">IT Dev Solution est votre partenaire privilégié pour une transformation numérique réussie. Nous nous engageons à fournir des solutions innovantes et sécurisées, en veillant à ce que votre entreprise reste à l'avant-garde de l'évolution numérique.</p>  		
                            
                            <Link to="" className="button-header">Contactez-Nous</Link>
                        </div>
                        <div className="imagebreadcrumb-right">
                        <div className="overlay-img--cubeLg1 position-absolute b-6"></div>

                        <img src="../../solutions/aboutus-header.png" alt="Image 1"/>
                        </div>
                    </div>
                    </section>

                    <section className="about-us section-padding inner-page-about-us">
                        <svg className="bg-shape shape-about reveal-from-bottom" xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink" width="779px" height="759px">
                            <defs>
                                <linearGradient id="PSgrad_03" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                    <stop offset="0%" stopColor="rgb(237,247,255)" stopOpacity="1" />
                                    <stop offset="100%" stopColor="rgb(237,247,255)" stopOpacity="0" />
                                </linearGradient>

                            </defs>
                            <path fillRule="evenodd" fill="url(#PSgrad_03)" d="M111.652,578.171 L218.141,672.919 C355.910,795.500 568.207,784.561 692.320,648.484 C816.434,512.409 805.362,302.726 667.592,180.144 L561.104,85.396 C423.334,-37.184 211.037,-26.245 86.924,109.832 C-37.189,245.908 -26.118,455.590 111.652,578.171 Z"
                            />
                        </svg>
    
                    </section>
                    <section className="container-fluid light-bg pb-5 overflow-hidden">
        <div className="container">
          <div className="row row-cols-2 gx-5 align-items-center">
            <div className="col-12 col-md-6">
              <h2>
              IT DEV Solution - Accélérer la transformation digitale des organisations
              </h2>
              <p className="py-4 text-justify">
             Nous sommes une entreprise de services numériques, fièrement soutenue à 100 % par le Fonds National d'Investissement (FNI). Notre objectif est d'accompagner les entreprises et les organismes algériens dans leur voyage vers la transformation digitale, en offrant des solutions
              </p>
              <ul className="px-3">
                      <li>Novatrices</li>
                      <li>Sécurisées</li>
                      <li>Conformes</li>
                    </ul>
            </div>
            <div className="col-12 col-md-6 img-wrapper position-relative p-0">
              <div className="p-4">
                <div className="overlay-img--cubeLg position-absolute b-6"></div>
                <img
                  className="img-fit-cover b-6"
                  src="./ITDS.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid light-bg overflow-hidden mt-5 mt-md-4">
        <div className="container">
          <div className="row gy-5 gy-md-0">
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div
                  className="col-12 col-md-6 p-3 p-lg-5 position-relative order-md-1 order-2"
                >
                  <div className="position-relative">
                    <div
                      className="overlay-img--cubeSm p-lu position-absolute b-6"
                    ></div>
                    <img
                      className="img-fit-cover b-6"
                      src="./service/iabout.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-2 order-1">
                  <h5>Notre Identité</h5>
                  <p className="py-3 text-justify">
                  Chez nous, l'agilité et la créativité sont au cœur de notre approche. Nous croyons en la puissance de la technologie pour résoudre les défis les plus complexes et pour ouvrir de nouvelles perspectives. Notre équipe talentueuse est composée d'experts passionnés, prêts à repousser les limites pour offrir des solutions sur mesure.
                  </p>
                  <p className="py-3 text-justify">
                  Nous sommes fiers de notre capacité à comprendre les besoins uniques de chaque client et à proposer des solutions sur mesure qui répondent à leurs objectifs spécifiques. Notre approche collaborative nous permet de travailler en étroite collaboration avec nos clients, en tant que partenaires de confiance, pour créer des solutions innovantes qui transforment leur activité.
                  </p>
                  <p className="py-3 text-justify">
                  Notre identité est également ancrée dans nos valeurs fondamentales. L'intégrité, la transparence et l'éthique guident chacune de nos actions. Nous nous engageons à respecter les normes les plus élevées de qualité et de service, en veillant à ce que nos clients bénéficient d'une expérience exceptionnelle à chaque étape de leur parcours avec nous.
                  </p>
                  <p className="py-3 text-justify">
                  </p>
               
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-12 col-md-6">
                  <h1>Notre Expertise</h1>
                  <p className="py-3 text-justify">
                  Notre expertise couvre l'intégralité de la chaîne de valeur des systèmes d'information, englobant des domaines tels que
                  <ul className="px-3 text-justify">
                      <li>Conseil</li>
                      <li>Intégration de solutions</li>
                      <li>Développement spécifique</li>
                      <li>Gestion de processus métiers</li>
                      <li>L'hébergement sécurisé</li>
                      <li>Cyber sécurité</li>
                    </ul>
                     et bien plus encore. Nous sommes fermement attachés à garantir que tous nos projets respectent les cadres réglementaires en vigueur, assurant ainsi la sécurité et la conformité à chaque étape de notre collaboration.
                  </p>
                </div>
                <div className="col-12 col-md-6 p-3 p-lg-5 position-relative">
                  <div className="position-relative">
                    <div
                      className="overlay-img--cubeSm p-rc position-absolute b-6"
                    ></div>
                    <img
                      className="img-fit-cover b-6"
                      src="./service/exprtise.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row gx-5 gy-3 align-items-center">
                <div
                  className="col-12 col-md-6 p-3 p-lg-5 position-relative order-md-1 order-2"
                >
                  <div className="position-relative">
                    <div
                      className="overlay-img--cubeSm p-lc position-absolute b-6"
                    ></div>
                    <img
                      className="img-fit-cover b-6"
                      src="./service/cstumer.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 order-md-2 order-1">
                  <h1>Notre Engagement Client</h1>
                  <p className="py-3 text-justify">
                  La satisfaction de nos clients demeure au cœur de nos préoccupations. Nous entretenons des relations de confiance durables, fondées sur la transparence, l'engagement continu et un sens aigu du service. Notre vision est de devenir le partenaire de choix en Algérie pour accompagner la transformation digitale des organisations publiques et privées, favorisant ainsi une croissance durable et une adaptation réussie aux défis du monde numérique contemporain.                  </p>
                 
                </div>
              </div>
            </div>
           
            
          </div>
        </div>
      </section>
      <section
        className="container-fluid py-5 my-md-3 my-lg-5"
      >
          <div className="container light-bg">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 py-5">
          <h1>OUR BUSINESS IS YOUR SUCCESS</h1>
          <p className="my-4 text-justify">
          C'est avec une grande fierté que nous nous tournons vers vous, alors que nous continuons à tracer notre chemin vers l'excellence dans le paysage numérique algérien.
          Notre entreprise, en tant que filiale à part entière du Fonds National d'Investissement, s'est engagée à guider et accompagner nos entreprises et organismes nationaux dans leur voyage vers la transformation digitale. 
          Notre dévouement à cette mission se reflète dans chacune de nos actions, en nous efforçant de proposer des solutions innovantes, sécurisées et conformes qui repoussent les limites de l'industrie.
          </p>
          <p className="my-4 text-justify">
          En plaçant la satisfaction de nos clients au cœur de nos priorités, nous cherchons à établir des relations durables basées sur la transparence, la confiance et un service personnalisé. Notre vision consiste à devenir le partenaire incontournable pour la transformation digitale en Algérie, offrant un avenir prometteur aux organisations publiques et privées à travers le pays.          </p>
          <p className="my-4 text-justify">
          Ensemble, continuons à construire un avenir numérique plus sûr, plus innovant et plus prospère pour notre nation.

</p>

          
        </div>
        <div className="col-12 col-md-6 video-wrapper overflow-hidden b-6 position-relative">
          <div
            className="action-wrapper position-absolute d-flex align-items-center text-white"
            style={{ bottom: "10%", left: "6%" }}
          >
            <i className="bi bi-play-circle-fill fs-1"></i>
            <a href="#" className="mx-3 text-decoration-none text-white"></a>
          </div>
          <a href="#">
            <img
              className="img-fit-cover b-6"
              src="./service/itdvabout.png"
              alt=""
              srcSet=""
            />
          </a>
        </div>
      </div>
    </div>
      </section>
      <Contact1/>

    
     
                </div>
            </div>
        )
    }
}

