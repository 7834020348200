
import React, { useState, MouseEvent } from "react";
import { Link } from "react-router-dom";

import { FeatureModal } from "../model/FeatureModel";
import { Container, Row, Col } from "react-bootstrap";

interface IFeaturesSection {
  title: string;
  slice: number;
  item: boolean;
}

const FeaturesSection = ({ title, slice, item }: IFeaturesSection) => {
  const [show, setShow] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState({ image: "", title: "", description: "" });

  const handleClose = () => setShow(false);
  const handleShow = (
    e: MouseEvent<HTMLAnchorElement | MouseEvent>,
    image: string,
    title: string,
    description: string
  ) => {
    e.preventDefault();
    setSelectedFeature({ image, title, description });
    setShow(true);
  };

  let features = [
    {
      image:"./ia.jpg",
      title: "IA ",
      subTitle: "Innovation / IA",
      description:
        "Notre approche innovante en matière d'IA se concentre sur le développement de modèles d'apprentissage automatique avancés, de solutions de traitement du langage naturel et de systèmes prédictifs intelligents, visant à offrir des solutions sur mesure pour une large gamme d'applications, allant de l'automatisation des processus à la personnalisation des expériences utilisateur. ",
    },
    {
      image:"./Sécurité.jpg",
      title: "Cybersécurité",
      subTitle: "Sécurité",
      description:
        "Notre approche de cybersécurité repose sur une combinaison de solutions de pointe, d'expertise en matière de gestion des risques et d'une approche proactive en matière de surveillance et de défense des données. Nous nous engageons à garantir la sécurité et la confidentialité des informations sensibles, en mettant l'accent sur la prévention des cybermenaces et la conformité aux normes de sécurité les plus strictes.",
    },
    {
      image:"./conceptionweb.jpg",
      title: "Conception Web ",
      subTitle: "Web Design",
      description:
        "Notre approche de conception web se concentre sur la création d'interfaces utilisateur intuitives, esthétiques et conviviales. Nous mettons l'accent sur l'expérience utilisateur optimale, en combinant des designs créatifs avec une fonctionnalité solide pour offrir des sites web attrayants et performants qui captivent et engagent les utilisateurs.",
    },
    {
      image:"./securité.png",
      title: "Digital Agency",
      subTitle: "Web Design",
      description:
        "This project is a showcase of our web design expertise for a digital agency. We've collaborated with the agency to create visually appealing websites that not only represent their brand but also engage visitors effectively. Our designs are tailored to their unique needs.",
    },
    {
      image:"/images/featured-projects/featured-project-5.svg",
      title: "Agency Web Application",
      subTitle: "Web Design",
      description:
        "Our Agency Web Application project involves the development of a web-based application for a creative agency. The application serves as a hub for managing projects, client interactions, and team collaboration. It's designed to streamline agency operations and enhance their online presence.",
    },
    {
      image:"/images/featured-projects/featured-project-6.svg",
      title: "Digital Agency (Web Development)",
      subTitle: "Web Development",
      description:
        "In this project, we've taken on web development tasks for a digital agency. Our focus is on creating functional and responsive websites that align with the agency's goals. From front-end development to back-end coding, we provide comprehensive web development solutions.",
    },
  ];

  if (slice) {
    features = features.slice(0, slice);
  }

  if (item) {
    features.map((feature, index) => {
      index++;
      feature.title = "Oops! Under maintenance.";
      feature.description =
        "This part of the site is under maintenance for technical reasons. Try again later!";
      feature.image = `/images/featured-projects/featured/project-featured-${index}.svg`;
      return feature;
    });
  }

  return (
    <React.Fragment>
      <section className="featured-projects section-padding">
        <Container>
          <h1 className={!item ?  "text-center black-header" : ""}>{title}</h1>
          {!item ? (
            <p className="section-subheading text-center ">
             La stratégie de croissance d'IT Dev Solution se concentre principalement sur l'élargissement de ses domaines d'activités pour renforcer sa position sur le marché et assurer une satisfaction constante à ses clients, tout en améliorant leurs systèmes d'information.
            </p>
          ) : (
            ""
          )}
          <div className="featured-project-showcase text-center">
            <Row>
              {features.map(({ image, title, subTitle, description }, index) => {
                return (
                  <Col
                    md={6}
                    lg={4}
                    className={`grid-item ${item ? "featured-item" : ""}`}
                    key={index}
                  >
                    <Link
                      to={`#featured-${index}`}
                      className="featured-content-block content-block"
                      onClick={e => handleShow(e, image, title, description)}
                    >
                      <div className="img-container">
                        <img
                          src={image}
                          alt={`featured-${index}`}
                          className="img-fluid"
                          height="0"
                          width="0"
                        />
                      </div>
                      <h5>
                        <span className="content-block__sub-title">{subTitle}</span>
                        {title}
                      </h5>
                    </Link>
                  </Col>
                );
              })}
           
            </Row>
          </div>
        </Container>
      </section>
      {
        <FeatureModal
          show={show}
          handleClose={handleClose}
          image={selectedFeature.image}
          alt={`Modal for ${selectedFeature.title}`}
          title={selectedFeature.title}
          description={selectedFeature.description}
          removeBtn={item || isNaN(slice)}
        />
      }
    </React.Fragment>
  );
};

export { FeaturesSection };