import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop";
import Layout from "./components/Layout";
import Home from "./components/home/Home";
import Portfolio from "./components/portfolio/Portfolio";
import Pricing from "./components/pricing/Pricing";
import CaseStudies from "./components/case-studies/CaseStudies";
import About from "./components/about/About";
import WebsiteDesign from "./components/services/website/design/WebsiteDesign";
import WebsiteDevelopment from "./components/services/website/development/WebsiteDevelopment";
import Intégration from "./components/services/intégration/Intégration";
import Entrepot from "./components/services/entrepot/Entrepot";
import AppDevelopment from "./components/services/appDevelopment/AppDevelopment";
import Hébergement from "./components/services/Hébergement/Hébergement";
import Sécurité from "./components/services/Sécurité/Sécurité";
import Account from "./components/account/Account";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import TermsAndConditions from "./components/legal/TermsConditions";
import LegalNotice from "./components/legal/LegalNotice";
import Parteners from "./components/parteners/Parteners";
import Contactus from "./components/Contactus/Contactus";
import Conformité from "./components/services/Conformité/Conformité";
import CentresAppels from "./components/services/CentresAppels/CentresAppels";
import Services from "./components/services/services/services";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/EspaceCarrière" element={<CaseStudies />} />
            <Route path="/parteners" element={<Parteners/>}/>
            <Route path="/A propos" element={<About />} />
            <Route path="/Conseil/et/Intégration" element={<WebsiteDesign />} />
            <Route path="/service/Développement/sur/mésure" element={<WebsiteDevelopment />} />
            <Route path="/service/Intégration" element={<Intégration />} />
            <Route path="/service/Entrepot" element={<Entrepot />} />
            <Route path="/service/Infogérance" element={<AppDevelopment />} />
            <Route path="/service/Hébergement" element={<Hébergement />} />
            <Route path="/service/Sécurité" element={<Sécurité />} />
            <Route path="/my/account" element={<Account />} />
            <Route path="/privacy/policy" element={<PrivacyPolicy />} />
            <Route path="/terms/and/conditions" element={<TermsAndConditions />} />
            <Route path="/legal/notice" element={<LegalNotice />} />
            <Route path="/service/Conformité" element={<Conformité />} />
            <Route path="/service/CentresAppels" element={<CentresAppels />} />
            <Route path="/service/services" element={<Services />} />
            <Route path="/Contactus/Contactus" element={<Contactus />}></Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;